import { useGetMe, UserModel } from 'api-hooks/auth';
import notification from 'common/helpers/notification';
import { NavigationRoutes } from 'common/routes';
import { sendNativeMessage } from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import useDetectDevice from 'hooks/use-detect-device';
import { useEffectLimits } from 'hooks/use-effect-derivatives';
import useKurosimNavigation, {
  useDelayedRedirect,
} from 'hooks/use-kurosim-navigation/navigator';
import useOauthResumeFlow from 'hooks/use-oauth-resume-flow';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SessionToken } from './token';
import useOauthSessionWeb from './use-oauth-session-web';

/*
24/06/2024: No need to store me data in localStorage anymore.
Potential privacy risk for users on shared computers and getMeStorage is not used anywhere.
We'll still need to sync whenever we receive new me data though.
*/
export function syncMeData(user: UserModel | undefined) {
  sendNativeMessage({
    type: BridgeMessageType.SyncMe,
    data: user ?? null,
  });
}

interface UseAuthProps {
  queryProps: Parameters<typeof useGetMe>[0];
}

export default function useAuth(props?: UseAuthProps) {
  const queryGetMe = useGetMe(props?.queryProps);
  const { data, error, isFetching } = queryGetMe;
  const { isKurosimApp } = useDetectDevice();
  const { status } = useSession();
  const isAuthenticated = !!data;
  const firstTime = React.useRef(false);
  const isRedirectLogin = React.useMemo(() => {
    const isBasicUnuthenticated = !isFetching && error?.statusCode === 401;
    const isOauthUnauthenticated = status === 'unauthenticated';
    if (isKurosimApp) {
      return isBasicUnuthenticated;
    } else {
      return isBasicUnuthenticated && isOauthUnauthenticated;
    }
  }, [error?.statusCode, isFetching, isKurosimApp, status]);

  React.useEffect(() => {
    if (isFetching) return;

    // Sync data with Native
    if (error?.statusCode === 401) {
      syncMeData(undefined);
    } else if (data?.data) {
      syncMeData(data.data);
    }
  }, [data?.data, error, isFetching]);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    if (firstTime.current) return;
    if (SessionToken.get()) return;
    firstTime.current = true;
    const interval = setInterval(() => {
      if (SessionToken.get()) {
        queryGetMe.refetch();
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [queryGetMe]);

  return React.useMemo(() => {
    return {
      isAuthenticated,
      isRedirectLogin,
      isOauthLoading: status === 'loading',
      ...queryGetMe,
    };
  }, [isAuthenticated, isRedirectLogin, queryGetMe, status]);
}

interface UseLoginIfUnauthenticatedProps {
  fn: () => Promise<void>;
  beforeRedirect?(): void;
}
// Used in public routes which needs to redirect to login screen
export function useLoginIfUnauthenticated(
  props: UseLoginIfUnauthenticatedProps,
) {
  const { fn, beforeRedirect } = props;
  const { t } = useTranslation();
  const redirect = useDelayedRedirect({
    message: t('common:redirecting_to_login_screen'),
    navigate({ push }) {
      beforeRedirect?.();
      push(NavigationRoutes.Login);
    },
  });
  return async () => {
    try {
      await fn();
    } catch (e) {
      console.error(e);
      if (e.message) {
        notification.error({ message: e.message });
      }
      if (e.statusCode === 401) {
        redirect();
      }
    }
  };
}

export function HandleRefreshOauthComponent() {
  const { onGetSessionToken, session } = useOauthSessionWeb();
  const { isKurosimApp } = useDetectDevice();
  const { pathname } = useRouter();

  const canRunEffect = useEffectLimits({
    times: 1,
    // no need to fetch token if user is in app (native will handle it) or if there's no pending token from Google/Apple
    enabled: !!session && !isKurosimApp,
    condition(props) {
      // no need to fetch token if user already has access/refresh token
      return !SessionToken.get();
    },
  });
  const { push } = useKurosimNavigation();
  const resume = useOauthResumeFlow();

  React.useEffect(() => {
    if (!canRunEffect()) return;

    async function exec() {
      try {
        const result = await onGetSessionToken();
        if (!result) return;
        await resume(result.data);
      } catch (e) {
        console.error(e);
      }
    }
    exec();
  }, [
    isKurosimApp,
    canRunEffect,
    onGetSessionToken,
    session,
    push,
    pathname,
    resume,
  ]);

  return null;
}

export const CurrentUserContext = React.createContext<UserModel | null>(null);
