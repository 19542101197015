import { useAuth } from 'common/auth';
import { NavigationRoutes } from 'common/routes';
import { useDelayedRedirect } from 'hooks/use-kurosim-navigation/navigator';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const RoutePublicness: Record<NavigationRoutes, boolean> = {
  [NavigationRoutes.AddToHomeScreen]: true,
  [NavigationRoutes.AffiliateAccountSettings]: false,
  [NavigationRoutes.AffiliateRegistration]: false,
  [NavigationRoutes.AllPaymentCards]: false,
  [NavigationRoutes.AllEsims]: false,
  [NavigationRoutes.AllSubscriptions]: false,
  [NavigationRoutes.Cart]: false,
  [NavigationRoutes.Checkout]: false,
  [NavigationRoutes.CheckoutCart]: false,
  [NavigationRoutes.ContactUs]: true,
  [NavigationRoutes.CustomizeDesign]: false,
  [NavigationRoutes.Devices]: false,
  [NavigationRoutes.Esim]: false,
  [NavigationRoutes.EsimActivation]: false,
  [NavigationRoutes.EsimAllPlans]: true,
  [NavigationRoutes.EsimHistory]: false,
  [NavigationRoutes.EsimInstallGuide]: false,
  [NavigationRoutes.EsimPlanDetail]: true,
  [NavigationRoutes.EsimPlanSubscribe]: false,
  [NavigationRoutes.EsimPublic]: true,
  [NavigationRoutes.EsimPublicInstall]: true,
  [NavigationRoutes.EsimUpcoming]: false,
  [NavigationRoutes.Feedback]: false,
  [NavigationRoutes.ForgotPassword]: true,
  [NavigationRoutes.Guides]: true,
  [NavigationRoutes.LatestFeedbacks]: false,
  [NavigationRoutes.Login]: true,
  [NavigationRoutes.MySim]: true,
  [NavigationRoutes.NewPaymentCard]: false,
  [NavigationRoutes.NotFound]: true,
  [NavigationRoutes.Notifications]: false,
  [NavigationRoutes.OrderDetail]: false,
  [NavigationRoutes.Orders]: true,
  [NavigationRoutes.OtpVerification]: true,
  [NavigationRoutes.Payment]: false,
  [NavigationRoutes.PaymentSettle]: false,
  [NavigationRoutes.PaymentStatus]: false,
  [NavigationRoutes.CartPlanDetail]: false,
  [NavigationRoutes.Profile]: true,
  [NavigationRoutes.ProfileDelete]: false,
  [NavigationRoutes.ProfileEdit]: false,
  [NavigationRoutes.ProfileEditEmail]: false,
  [NavigationRoutes.ProfileEditPassword]: false,
  [NavigationRoutes.ProfileEditPhone]: false,
  [NavigationRoutes.ProfileSettingNotification]: true,
  [NavigationRoutes.Purchase]: true,
  [NavigationRoutes.PurchaseAboutEsim]: true,
  [NavigationRoutes.PurchasePlanDetails]: true,
  [NavigationRoutes.Recharge]: false,
  [NavigationRoutes.Referral]: true,
  [NavigationRoutes.ReferralBind]: false,
  [NavigationRoutes.ReferralPerformance]: false,
  [NavigationRoutes.__RegisterRoutePrefix]: true,
  // [NavigationRoutes.RegisterAppTracking]: true,
  [NavigationRoutes.RegisterPushNotifications]: true,
  [NavigationRoutes.ResetPassword]: true,
  [NavigationRoutes.Search]: true,
  [NavigationRoutes.Store]: true,
  [NavigationRoutes.ViewPaymentCard]: false,
  [NavigationRoutes.WalletMutationHistory]: false,
  [NavigationRoutes.XenditPayment]: false,
  [NavigationRoutes.PaymentStatusCheckEsim]: false,
  [NavigationRoutes.KuroPointsWithdraw]: false,
  [NavigationRoutes.KuroPointsWithdrawals]: false,
};

export function isProtectedRoutes(pathname: string) {
  return RoutePublicness[pathname as NavigationRoutes] === false;
}

export function isPublicRoutes(pathname: string) {
  return RoutePublicness[pathname as NavigationRoutes] === true;
}

export default function PrivateRoute({ children }) {
  const router = useRouter();

  const { isRedirectLogin, isLoading } = useAuth();

  const pathIsProtected = isProtectedRoutes(router.pathname);
  const { t } = useTranslation();
  const redirect = useDelayedRedirect({
    message: t('common:redirecting_to_login_screen'),
    navigate({ push }) {
      push(NavigationRoutes.Login);
    },
  });

  React.useEffect(() => {
    if (!pathIsProtected || isLoading) return;
    if (
      isRedirectLogin &&
      !(
        router.pathname.includes(NavigationRoutes.__RegisterRoutePrefix) ||
        router.pathname === NavigationRoutes.OtpVerification
      )
    ) {
      redirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isRedirectLogin, pathIsProtected]);

  return <>{children}</>;
}
