import { useUpdateSettings } from 'api-hooks/account';
import { allQueryExceptToken, LanguageType, meKey } from 'api-hooks/auth';
import { queryClient } from 'common/api/query-client';
import { SessionToken } from 'common/auth';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import notification from 'common/helpers/notification';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

import { useEffectLimits } from './use-effect-derivatives';
import yupEnValidation from '../../locales/en/validation.yup';
import yupIdValidation from '../../locales/id/validation.yup';
import useAuth from '../common/auth/use-auth';

export function getBrowserLanguage() {
  if (typeof navigator === 'undefined') {
    return 'en';
  }
  const browserLanguage = navigator.language;
  if (browserLanguage.startsWith('id')) {
    return 'id';
  }
  return 'en';
}

export function getLanguageStorage(): LanguageType | undefined {
  if (typeof window === 'undefined') return undefined;
  const result = localStorage.getItem(
    LocalStorageKeys.Language,
  ) as LanguageType | null;
  return result || undefined;
}

export function setLanguageStorage(value: LanguageType) {
  if (typeof window === 'undefined') return;
  localStorage.setItem(LocalStorageKeys.Language, value);
}

export function selectLanguage<T>(choices: Record<LanguageType, T>): T {
  const lang = getLanguageStorage() ?? getBrowserLanguage();
  const choice = choices[lang];
  if (!choice) {
    return Object.values(choices).find((x) => !!x) as T;
  }
  return choice;
}

interface LanguageContextProps {
  language: LanguageType;
  handleLanguage: (value: LanguageType) => void;
}

export const LanguageContext = React.createContext<LanguageContextProps>({
  language: 'id',
  handleLanguage: () => {},
});

export function LanguageProvider({ children }) {
  const [language, setLanguage] =
    React.useState<LanguageType>(getBrowserLanguage());
  const { i18n } = useTranslation();
  const { data: me, isFetching: isAuthenticating, isAuthenticated } = useAuth();
  const { mutateAsync: updateSettingAsync } = useUpdateSettings();
  const lang = useRouter().query.lang as LanguageType;

  const send = useNativeBridge({
    handlers: {},
  });

  const meLanguage = me?.data?.settings?.language;

  const updateLocalLanguage = React.useCallback(
    (value: LanguageType) => {
      const previousStoredLanguage = getLanguageStorage();
      const isStorageLanguageChanged = previousStoredLanguage !== value;
      const isAppLanguageChanged = i18n.language !== value;
      const isClientLanguageChanged = language !== value;

      if (isStorageLanguageChanged) {
        setLanguageStorage(value);
      }
      if (isAppLanguageChanged) {
        i18n.changeLanguage(value);
      }
      if (isClientLanguageChanged) {
        setLanguage(value);
      }

      if (value === 'en') {
        setLocale(yupEnValidation);
      } else {
        setLocale(yupIdValidation);
      }

      // to native
      send?.({
        data: value,
        type: BridgeMessageType.language,
      });

      return {
        isStorageLanguageChanged,
      };
    },
    [i18n, language, send],
  );

  const handleLanguage = React.useCallback(
    async (value: LanguageType) => {
      const { isStorageLanguageChanged } = updateLocalLanguage(value);
      const isMeLanguageChanged = meLanguage !== value;

      // when no authenticated
      // Invalidate all cached data. This is because the data cached in query client uses Accept-Language=<prev lang>, but if we change the language to <new lang>, the data cached in query client will no longer be appropriate as they're in different languages, so the queries have to be invalidated.
      if (typeof SessionToken.get() === 'undefined' && !me) {
        isStorageLanguageChanged &&
          queryClient.invalidateQueries({
            predicate(query) {
              return (
                query.queryKey[0] !== meKey.getMeKey[0] ||
                query.queryKey[0] !== meKey.getCacheTokenKey[0]
              );
            },
          });
        return;
      }

      if (!isMeLanguageChanged) return;

      // api fetching
      try {
        await updateSettingAsync({
          language: value,
        });
      } catch (e) {
        console.error(e);
        notification.error({
          message: e.message,
        });
      } finally {
        isStorageLanguageChanged &&
          queryClient.invalidateQueries({
            predicate: allQueryExceptToken,
          });
      }
    },
    [me, meLanguage, updateLocalLanguage, updateSettingAsync],
  );

  const canRunEffect = useEffectLimits({
    times: 1,
    enabled: !isAuthenticating,
    key: me?.data.id,
  });

  // Set local language on startup.
  const canSetLocaleOnce = useEffectLimits({
    times: 1,
  });

  React.useEffect(() => {
    if (!canSetLocaleOnce()) {
      return;
    }
    updateLocalLanguage(getLanguageStorage() ?? getBrowserLanguage());
  }, [canSetLocaleOnce, updateLocalLanguage]);

  React.useEffect(() => {
    if (!canRunEffect()) {
      return;
    }
    const storedLanguage = getLanguageStorage();
    if (isAuthenticated && meLanguage) {
      // User is authenticated, always refer to the stored language preference
      handleLanguage(meLanguage);
    } else {
      // This is the first time the user entered Kurosim, set default language.
      // If lang is provided, also set language.
      handleLanguage(lang ?? storedLanguage ?? getBrowserLanguage());
    }
  }, [
    handleLanguage,
    isAuthenticated,
    isAuthenticating,
    lang,
    canRunEffect,
    meLanguage,
  ]);

  return (
    <LanguageContext.Provider value={{ language, handleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export default function useLanguage() {
  const context = React.useContext(LanguageContext);
  return context;
}
