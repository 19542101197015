import notification from 'common/helpers/notification';
import {
  type NavigationRouteConfig,
  type NavigationRouteParams,
  NavigationRoutes,
} from 'common/routes';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { trimObject } from 'common/utils/iterable';
import { generateUniqueId } from 'common/utils/random';
import useDetectDevice from 'hooks/use-detect-device';
import { useEffectLimits } from 'hooks/use-effect-derivatives';
import { useGetNotFoundIndicator } from 'hooks/use-get-not-found-indicator';
import { useSingletonTimeout } from 'hooks/use-timer';
import { useRouter } from 'next/router';
import React from 'react';

import { InterPageDataContext, LOCKER_IDENTIFIER } from './inter-page';
import { StackNavigation } from './session-navigation';
import type { KurosimNavigator } from './types';
import { backWithoutHistory } from './utils';
import useGetParentRef, { ParentRefType } from '../use-parent-ref';

const windowInitiated = typeof window !== 'undefined';

export default function useKurosimNavigation(): KurosimNavigator {
  const router = useRouter();
  const { push, replace, query, prefetch, pathname } = router;
  const { isNotFound } = useGetNotFoundIndicator();
  const lockers = React.useContext(InterPageDataContext);
  const scrollRef = useGetParentRef(ParentRefType.Scroll);
  const id = query.id;
  const slug = query.slug;

  const paths = trimObject({
    id,
    slug,
  });

  // This is to fix broken dynamic routes
  const canReplaceRecentPathname = useEffectLimits({
    times: 1,
  });
  React.useEffect(() => {
    if (!canReplaceRecentPathname()) return;
    const recent = StackNavigation.current();
    if (!recent) return;
    if (recent.pathname !== pathname) {
      StackNavigation.update(recent.pathname, {
        ...recent,
        query,
        pathname,
      });
      console.log(`Replaced broken path ${recent.pathname} with ${pathname}`);
    }
  }, [canReplaceRecentPathname, pathname, query]);

  function process(route: string, props?: NavigationRouteConfig<any>) {
    let lockerId;
    if (props?.locker) {
      // Data will be accessed using useInterPageData
      lockerId = generateUniqueId(8);
      lockers[lockerId] = props?.locker;
    }
    return {
      pathname: route as any,
      query: {
        ...(lockerId
          ? {
              [LOCKER_IDENTIFIER]: lockerId,
            }
          : undefined),
        ...props?.query,
        ...props?.paths,
      },
    };
  }

  const goBack = () => {
    if (!windowInitiated) return;
    // Clean up lockers to prevent memory leak
    const lockerIdentifier = query[LOCKER_IDENTIFIER] as string;
    if (lockerIdentifier) {
      delete lockers[lockerIdentifier];
    }

    // Pop the current route first
    StackNavigation.pop();
    // Get the route before the current route
    const recent = StackNavigation.current();
    if (!recent) {
      // Oops, there's no previous root. Redirect to fallback route.
      const route = backWithoutHistory(
        router.pathname as NavigationRoutes,
        isNotFound,
      );
      const hasPaths = ['[id]', '[slug]'].find((value) => {
        return route.includes(value);
      });
      replace(process(route, hasPaths ? { paths } : undefined));
      return;
    }
    // Replace current route with previous route rather than using back(). Back() won't work if we intentionally, manually changed the history.
    // For example: login -> register -> push notifications, the user shouldn't be able to return to the register screen, so a reset must be performed so that the history becomes: store -> push notifications, which means back() should bring the user to the store page. However, using next/router's back() will bring us back to register instead.
    replace(recent);
  };

  /** Loads the newest stack navigation current entry */
  const refresh = () => {
    const recent = StackNavigation.current();
    if (!recent) {
      // Oops, there's no previous root. Redirect to fallback route.
      const route = backWithoutHistory(
        router.pathname as NavigationRoutes,
        isNotFound,
      );
      const hasPaths = ['[id]', '[slug]'].find((value) => {
        return route.includes(value);
      });
      replace(process(route, hasPaths ? { paths } : undefined));
      return;
    }
    return replace(recent);
  };

  return {
    history: StackNavigation.get(),
    currentRoute: () => {
      return {
        pathname: router.pathname,
        query,
        scrollY: scrollRef.current?.scrollTop,
      };
    },
    refresh,
    back: goBack,
    push<Route extends NavigationRoutes>(
      route: Route,
      props?: NavigationRouteParams[Route],
    ) {
      if (!windowInitiated) return Promise.resolve(false);
      const payload = process(route, props);
      const history = StackNavigation.get();
      if (history.length > 0) {
        // Save scroll position
        history[history.length - 1].scrollY = scrollRef.current?.scrollTop;
        StackNavigation.set(history);
      }
      // Note that the ``push`` method ensures that the routes are unique, so this is different to StackNavigation.set([...history, entry])
      StackNavigation.push(payload);
      return push(payload);
    },
    replace<Route extends NavigationRoutes>(
      route: Route,
      props?: NavigationRouteParams[Route],
    ) {
      if (!windowInitiated) return Promise.resolve(false);
      const payload = process(route, props);
      StackNavigation.replace(payload);
      return replace(payload);
    },
    reset<Route extends NavigationRoutes>(
      route: Route,
      props?: NavigationRouteParams[Route],
    ) {
      if (!windowInitiated) return Promise.resolve(false);
      const payload = process(route, props);
      StackNavigation.set([payload]);
      return replace(payload);
    },
    prefetch,
  };
}

export function useExternalSiteNavigation() {
  const { isKurosimApp } = useDetectDevice();
  const send = useNativeBridge({});
  return React.useCallback(
    (route: string) => {
      if (isKurosimApp) {
        send?.({
          data: route,
          type: BridgeMessageType.ExternalLinks,
        });
      } else {
        window.open(route, '_blank');
      }
    },
    [isKurosimApp, send],
  );
}

interface UseDelayedRedirectProps {
  message: string;
  navigate(navigator: KurosimNavigator): void;
}

export function useDelayedRedirect(props: UseDelayedRedirectProps) {
  const { setTimeout } = useSingletonTimeout();
  const navigator = useKurosimNavigation();
  return () => {
    // Add some delay so that user can actually know what's going on than randomly being sent to the login screen.
    const DELAY_MILLIS = 1500;
    notification.info({
      id: 'redirecting',
      message: props.message,
      loading: true,
      autoClose: DELAY_MILLIS + 1000,
    });
    setTimeout(() => {
      props.navigate(navigator);
    }, DELAY_MILLIS);
  };
}
