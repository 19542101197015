import { NavigationRoutes } from 'common/routes';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import { useRouter } from 'next/router';
import React from 'react';

export default function KurosimNavigationBridgeController() {
  const router = useRouter();
  const { replace } = useKurosimNavigation();
  const firstTime = React.useRef(false);

  const send = useNativeBridge({
    handlers: {
      // deeplink
      [BridgeMessageType.KurosimWebNavigation]: (message, context) => {
        const path = decodeURIComponent(message.data);
        router.replace(path);
      },

      // tab navigation
      [BridgeMessageType.KurosimTabNavigation]: (message, context) => {
        const path = message.data;
        switch (path) {
          case 'home':
            replace(NavigationRoutes.Store);
            break;
          case 'orders':
            replace(NavigationRoutes.Orders as any);
            break;
          case 'my-sim':
            replace(NavigationRoutes.MySim);
            break;
          case 'referral':
            replace(NavigationRoutes.Referral);
            break;
          case 'profile':
            replace(NavigationRoutes.Profile);
            break;
        }
      },
    },
  });

  // set bridge is ready
  React.useEffect(() => {
    if (firstTime.current) return;
    send?.({
      data: null,
      type: BridgeMessageType.KurosimWebLoaded,
    });
    firstTime.current = true;
  }, [send]);

  // control the bottom navigation native
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    if (firstTime.current === false) return; //should be called once
    switch (router.pathname as NavigationRoutes) {
      case NavigationRoutes.Store:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'home',
        });
        break;
      case NavigationRoutes.Orders:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'orders',
        });
        break;
      case NavigationRoutes.MySim:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'my-sim',
        });
        break;
      case NavigationRoutes.Referral:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'referral',
        });
        break;
      case NavigationRoutes.Profile:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'profile',
        });
        break;
      default:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: null,
        });
    }
  }, [router.pathname, send]);

  return null;
}
