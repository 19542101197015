import { TokenResultModel } from 'common/auth/api';
import notification from 'common/helpers/notification';
import { NavigationRoutes } from 'common/routes';
import { uniqueByKeepLast } from 'common/utils/iterable';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StackNavigation } from './use-kurosim-navigation';
import { useDelayedRedirect } from './use-kurosim-navigation/navigator';

export default function useOauthResumeFlow() {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const redirectReferralBind = useDelayedRedirect({
    message: t('referral:redirecting_to_referral_bind'),
    navigate({ push }) {
      const stack = StackNavigation.get();
      const importantRoutes = stack.filter((x) => !!x.important);
      stack.push(...importantRoutes);
      StackNavigation.set(uniqueByKeepLast(stack, (x) => x.pathname));
      push(NavigationRoutes.ReferralBind, {
        query: {
          code: '',
        },
      });
    },
  });

  const redirectPurchase = useDelayedRedirect({
    message: t('sim:resuming_previous_purchase'),
    navigate({ refresh }) {
      const purchaseEntry = StackNavigation.find(NavigationRoutes.Purchase);
      if (purchaseEntry) {
        StackNavigation.push(purchaseEntry);
      } else {
        StackNavigation.push({
          pathname: NavigationRoutes.Purchase,
          query: {},
        });
      }
      refresh();
    },
  });

  return React.useCallback(
    async (result: TokenResultModel) => {
      try {
        if (result.firstTime) {
          redirectReferralBind();
          return false;
        }

        const stack = StackNavigation.get();
        const isPendingPurchase = !!stack.find(
          (x) =>
            x.important &&
            x.pathname === NavigationRoutes.Purchase &&
            pathname !== NavigationRoutes.Purchase,
        );
        if (isPendingPurchase) {
          redirectPurchase();
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        if (e.message) {
          notification.error({ message: e.message });
        }
        return true;
      }
    },
    [pathname, redirectPurchase, redirectReferralBind],
  );
}
