import exportEnv from 'common/config';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';
import React from 'react';

export enum BrowserStorageType {
  Local = 'local',
  Session = 'session',
  Cookies = 'cookies',
}

interface BrowserStorageSyncProps<T> {
  value: T | null | undefined;
  key: string;
  isValid?(value: T | null | undefined): boolean;
  fallback: T;
}

export const cookies = {
  getItem: (key: string) => getCookie(key),
  setItem: (key: string, data: string, options?: OptionsType) =>
    setCookie(key, data, {
      ...options,
      ...(process.env.NODE_ENV === 'production'
        ? {
            secure: true,
            sameSite: 'lax',
            domain: exportEnv.domain,
          }
        : {}),
    }),
  removeItem: (key: string) => deleteCookie(key),
} as Storage;

function getBrowserStorage(type: BrowserStorageType): Storage {
  if (type === BrowserStorageType.Local) {
    return localStorage;
  } else if (type === BrowserStorageType.Cookies) {
    return cookies;
  } else {
    return sessionStorage;
  }
}

export function useBrowserStorageSync<T>(
  type: BrowserStorageType,
  props: BrowserStorageSyncProps<T>,
) {
  const { value, key, isValid, fallback } = props;
  React.useEffect(() => {
    const storage = getBrowserStorage(type);
    const valid = isValid ? isValid(value) : !!value;
    if (valid) {
      storage.setItem(key, JSON.stringify(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return React.useMemo(() => {
    if (value) return value;
    const storage = getBrowserStorage(type);
    const json = storage.getItem(key);
    if (json) {
      try {
        return JSON.parse(json);
      } catch {
        storage.removeItem(key);
      }
    }
    return fallback;
  }, [fallback, key, type, value]);
}

interface BrowserStorageStateProps<T> {
  parse?(raw: string): T;
  stringify?(value: T): string;
  key: string;
  fallback: T;
}

export function useBrowserStorageState<T>(
  type: BrowserStorageType,
  props: BrowserStorageStateProps<T>,
) {
  const { key, parse, stringify, fallback } = props;
  const [internal, setInternal] = React.useState<T>(fallback);
  React.useEffect(() => {
    const storage = getBrowserStorage(type);
    const found = storage.getItem(key);
    if (found != null) {
      setInternal(parse ? parse(found) : JSON.parse(found));
    } else {
      storage.setItem(key, fallback as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    internal,
    React.useCallback(
      (value: React.SetStateAction<T>) => {
        setInternal((prev) => {
          const storage = getBrowserStorage(type);
          const newValue: T =
            typeof value === 'function' ? (value as any)(prev) : value;
          storage.setItem(
            key,
            stringify ? stringify(newValue) : JSON.stringify(newValue),
          );
          return newValue;
        });
      },
      [key, stringify, type],
    ),
  ] as const;
}
